var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-8 pa-4 pdcard",attrs:{"max-height":"60vh"}},[_c('v-card-text',{staticClass:"text-h4 font-weight-light"},[_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"cols":"9","lg":"10"}},[_c('v-icon',{staticClass:"pdicon",style:({ 'max-height': '30px' }),attrs:{"size":"150"}},[_vm._v("$newPagerDuty")])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"3","lg":"2"}},[(!_vm.successIds.length && _vm.permissionsCheck)?_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.allowSave,"elevation":"0","loading":_vm.saving},on:{"click":_vm.createAllConfigs}},[_c('span',{staticStyle:{"text-transform":"none"}},[_vm._v("Save")])]):_vm._e()],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"mb-4"},[_c('span',[_vm._v(" Create an action to use with Prefect Automations and the Pager Duty "),_c('a',{attrs:{"href":"https://support.pagerduty.com/docs/services-and-integrations","target":"_blank"}},[_vm._v("Events API v2")]),_vm._v(". ")])]),(!_vm.integrationKeys || !_vm.integrationKeys.length)?_c('div',[_c('div',{staticClass:"text-center position-absolute center-absolute text-h5 utilGrayDark--text mt-10",style:({ 'z-index': 1, width: '100%' })},[_vm._v(" No integration keys ")])]):(
        _vm.integrationKeys && _vm.integrationKeys.length === _vm.successIds.length
      )?_c('div',{staticClass:"text-center"},[_c('v-data-table',{staticClass:"elevation-0",class:{ 'fixed-table': _vm.$vuetify.breakpoint.smAndUp },attrs:{"fixed-header":"","headers":_vm.headersByViewport,"items":_vm.actions,"items-per-page":10,"show-expand":"","hide-default-footer":"","loading":_vm.loadingTable || !_vm.actions || !_vm.actions.length,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 15, 20, -1],
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right'
        },"no-data-text":"No actions."},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"hidewidth"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.action_type)+" ")]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [(Object.keys(item.action_config).length)?_c('td',{attrs:{"colspan":headers.length}},[(Object.keys(item.action_config).length)?_c('dict-input',{staticClass:"mt-3",attrs:{"readonly-key":"","readonly-value":"","disable-add":"","disable-remove":"","value":_vm.formatItemValue(item)}}):_vm._e()],1):_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" No action config ")])]}}])}),_c('div',{staticClass:"mt-6",attrs:{"align":"center","justify":"center"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","outlined":"","to":{ name: 'actions' }}},[_vm._v("Manage Actions")]),_c('v-btn',{attrs:{"color":"primary","to":{
            path: ("/" + (_vm.tenant.slug) + "?automations=")
          }}},[_vm._v("Create Automation")])],1)],1):_c('div',[_vm._l((_vm.integrationKeys),function(key,index){return _c('v-row',{key:index,staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"data-public":"","items":_vm.severityLevels,"outlined":"","rules":_vm.rules,"label":"Severity"},model:{value:(key.severity),callback:function ($$v) {_vm.$set(key, "severity", $$v)},expression:"key.severity"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"rules":_vm.rules,"label":"Integration Key","outlined":""},model:{value:(key.integration_key),callback:function ($$v) {_vm.$set(key, "integration_key", $$v)},expression:"key.integration_key"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Action Name","outlined":""},model:{value:(key.name),callback:function ($$v) {_vm.$set(key, "name", $$v)},expression:"key.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-3",attrs:{"text":"","fab":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteItemFromList(key, index)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Remove action ")])],1)],1)}),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mx-auto px-8 text-none",attrs:{"depressed":"","text":"","color":"primary"},on:{"click":_vm.addKey}},[_vm._v(" Add Action "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" add ")])],1)],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }